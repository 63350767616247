import React, { useState, useEffect } from 'react'
import { parseTimestampToTimerTimeObj } from 'utils/utils'

import { TimerView } from './Timer.view'

export const Timer = ({ dueTimeTimestamp }: { dueTimeTimestamp: number }) => {
  const [timeLeft, setTimeLeft] = useState(parseTimestampToTimerTimeObj(dueTimeTimestamp))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(parseTimestampToTimerTimeObj(dueTimeTimestamp))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return <TimerView timerData={timeLeft} />
}
