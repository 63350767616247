import styled from 'styled-components/macro'

import { cyanColor, backgroundColor } from 'styles'

export const ToggleButtonWrapper = styled.div`
  display: flex;
  border: 1px solid ${cyanColor};
  border-radius: 20px;
  font-size: 16px;
  max-height: 36px;
  height: 100%;
  margin: 30px auto;
  width: 100%;
  max-width: 315px;

  &.small-size {
    max-width: 31%;
    .toggle-btn {
      max-width: 50%;
      padding: 0 10px;
    }
  }

  @media (max-width: 769px) {
    max-width: 240px;
  }
`

export const ToggleButtonItem = styled.div`
  padding: 10px 20px;
  height: 100%;
  max-width: 105px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s all ease-in-out;
  font-weight: 600;
  cursor: pointer;
  color: ${cyanColor};

  &.selected {
    background: ${cyanColor};
    border-radius: 17.5px;
    color: ${backgroundColor};
    transition: 0.4s all ease-in-out;
  }

  @media (max-width: 769px) {
    max-width: 80px;
    font-size: 14px;
  }
`
