import { selectPRCNode, setRPCNodes } from 'actions'
import { JoinMVKButton } from 'app/App.components/Content/Content.style'
import { Input } from 'app/App.components/Input/Input.controller'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'utils/interfaces'

import { DescrText, PopupTitle } from '../payment/Popup-payment.style'
import { ChangeNodeNodesList, ChangeNodeNodesListItem, PopupContainerWrapper } from './Popup-change-node.style'

export const PopupChangeNodeView = ({ closeModal }: { closeModal: () => void }) => {
  const [inputData, setInputData] = useState('')
  const selectedRPCNode = useSelector((state: State) => state.REACT_APP_RPC_PROVIDER)
  const RPCNodes = useSelector((state: State) => state.RPCNodes)
  const dispatch = useDispatch()
  const [selectedNodeByClick, setSelectedNodeByClick] = useState(selectedRPCNode)

  const addNodeHandler = () => {
    const newRPCNodes = [...RPCNodes, { title: `user${RPCNodes.length}`, url: inputData }]
    setInputData('')
    dispatch(setRPCNodes(newRPCNodes))
  }

  return (
    <PopupContainerWrapper>
      <div onClick={closeModal} className="close_modal">
        +
      </div>
      <PopupTitle className="change_node">Change RPC Node</PopupTitle>

      <ChangeNodeNodesList className="scroll-block">
        {RPCNodes.map(({ title, url, nodeLogoUrl }) => (
          <ChangeNodeNodesListItem onClick={() => setSelectedNodeByClick(url)} isSelected={selectedNodeByClick === url}>
            {nodeLogoUrl && (
              <div className="img_wrapper">
                <img src={`./images/${nodeLogoUrl}`} alt={title} />
              </div>
            )}{' '}
            <span className={title.includes('user') ? 'user-url' : ''}>
              {title.includes('user') ? `Link: ${url}` : title}
            </span>
          </ChangeNodeNodesListItem>
        ))}

        <ChangeNodeNodesListItem className="add_node">
          <div className="add-new-node-handler" onClick={() => addNodeHandler()}>
            Add New Node
          </div>
          <Input
            placeholder="https://..."
            name="add_new_node_input"
            value={inputData}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputData(e.target.value)}
          />
        </ChangeNodeNodesListItem>
      </ChangeNodeNodesList>

      <DescrText className="change_node">
        Changing node can improve stability and speed when the network is saturated.
      </DescrText>

      <JoinMVKButton
        onClick={() => {
          dispatch(selectPRCNode(selectedNodeByClick))
          closeModal()
        }}
        className="popup_btn default_svg start_verification"
      >
        <svg>
          <use xlinkHref="/icons/sprites.svg#okIcon" />
        </svg>
        Confirm
      </JoinMVKButton>
    </PopupContainerWrapper>
  )
}
