import { State, UserData, WalletState } from 'utils/interfaces'

export const PRECISION_NUMBER = 1000000000

export const MVK_USD_PRICE = 0.25

export const walletDefaultState: WalletState = {
  wallet: undefined,
  tezos: undefined,
  accountPkh: undefined,
  ready: false,
}

const defaultUser: UserData = {
  myAddress: '',
  myDelegationHistory: [],
  myMvkTokenBalance: 0,
  mySMvkTokenBalance: 0,
  participationFeesPerShare: 0,
  satelliteMvkIsDelegatedTo: '',
}

export const defaultState: State = {
  darkThemeEnabled: true,
  REACT_APP_RPC_PROVIDER: 'https://jakartanet.tezos.marigold.dev/',
  RPCNodes: [
    { title: 'MARIGOLD', url: 'https://jakartanet.tezos.marigold.dev/', nodeLogoUrl: 'marigold_logo.png' },
    { title: 'ECADLABS', url: 'https://jakartanet.ecadinfra.com', nodeLogoUrl: 'ECAD_logo.png' },
  ],
  scrollPosition: 0,
  wallet: walletDefaultState,
  user: defaultUser,
  timerCount: 1667995486000,
  MVKStats: {
    vestingPeriod: 18,
    maxPerWallet: 20000,
    whitelistMaxAmount: 200000,
    totalAmountAtPrice: 6000000,
    amountAvaliable: 3879009,
  },
  MVKPrices: [0.19, 0.54, 1.66],
  selectedMVKPrice: 0.19,
  tokensNumbersInfo: {
    totalWorthForSale: 90765989,
    avaliableTokens: 12237016,
    purchasedTokens: 6237016,
    totalRaised: 18902424.29,
  },
  tokensStatsTable: [
    {
      symbol: 'USD',
      amount: 512094643,
      usdPrice: 128023660.75,
    },
    {
      symbol: 'XTZ',
      amount: 312544643,
      usdPrice: 78136160.75,
    },
    {
      symbol: 'tzBTC',
      amount: 153,
      usdPrice: 38.25,
    },
  ],
}

export const BE_MAVRYK_BUY_TOKENS_POPUP = 'beMavrykBuyTokens'
export const BUY_MVK_VIA_CURRENCY_POPUP = 'buyMVKViaCurrency'
export const BUY_MVK_VIA_XTZ_POPUP = 'buyMVKViaXTZ'
export const START_VERIFICATION_POPUP = 'startVerification'
export const WERT_IO_POPUP_WRAPPER = 'wertIoPopup'

export const NUMBER_FORMATTER = new Intl.NumberFormat('en')

export const VERIFICATION_GRID_ITEMS = [
  {
    icon: 'creditCardIcon',
    text: 'Your bank card details',
  },
  {
    icon: 'selfieIcon',
    text: 'Your selfie with an ID',
  },
  {
    icon: 'photoIdIcon',
    text: 'A photo of your ID Document',
  },
  {
    icon: 'documentIcon',
    text: 'Address Document',
  },
]
