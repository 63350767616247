import styled from 'styled-components/macro'

export const PopupStyled = styled.div`
  .popup-enter {
    opacity: 0;
  }

  .popup-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .popup-exit {
    opacity: 1;
  }

  .popup-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .close_modal {
    position: absolute;
    font-size: 60px;
    font-weight: 400;
    height: 35px;
    width: 35px;
    color: #8d86eb;
    transform: rotate(45deg);
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
`

export const PopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #0000007a;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
`

// TODO: use when will be adding closee btn to each modal
// export const PopupClose = styled.div`
//   cursor: pointer;
//   z-index: 101;
//   position: fixed;
//   top: 230px;
//   right: calc(50vw - 620px);

//   svg {
//     width: 24px;
//     height: 24px;
//     stroke: #fff;
//   }
// `
