import { JoinMVKButton } from 'app/App.components/Content/Content.style'
import { BUY_MVK_VIA_CURRENCY_POPUP, BUY_MVK_VIA_XTZ_POPUP } from 'utils/consts'
import { AcceptedBlock, DescrText, PopupContainerWrapper, PopupTitle } from '../Popup-payment.style'

export const BeMavrykBuyTokensPopup = ({ changeActivePopup }: { changeActivePopup: (popupId: string) => void }) => (
  <PopupContainerWrapper className="be-mavryk-buy-tokens">
    <PopupTitle>Be Mavryk & Buy Tokens Now</PopupTitle>
    <DescrText>
      You have 2 options for buying MVK. First, using your own tez from wallet. Second, buying MVK with your credit card
      through wert.io.
    </DescrText>

    <div className="learn-more-link">
      Learn more about wert.io
      <svg>
        <use xlinkHref="/icons/sprites.svg#questionMark" />
      </svg>
    </div>

    <div className="buttons_wrapper">
      <JoinMVKButton onClick={() => changeActivePopup(BUY_MVK_VIA_CURRENCY_POPUP)} className="popup_btn dark">
        Buy with Wert
      </JoinMVKButton>
      <JoinMVKButton onClick={() => changeActivePopup(BUY_MVK_VIA_XTZ_POPUP)} className="popup_btn">
        <svg>
          <use xlinkHref="/icons/sprites.svg#XTZIcon" />
        </svg>
        Buy with XTZ
      </JoinMVKButton>
    </div>

    <AcceptedBlock>
      accepted here
      <svg id="visaIcon">
        <use xlinkHref="/icons/sprites.svg#visaIcon" />
      </svg>
      <svg id="mcIcon">
        <use xlinkHref="/icons/sprites.svg#mcIcon" />
      </svg>
    </AcceptedBlock>
  </PopupContainerWrapper>
)
