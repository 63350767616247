import styled from 'styled-components/macro'

export const PopupContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #160e3f;
  border: 1px solid #86d4c9;
  border-radius: 10px;
  height: fit-content;
  max-width: 395px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1165px) {
    width: 95vw;
    padding: 40px 20px;
  }

  .close_modal {
    position: absolute;
    font-size: 35px;
    height: 18px;
    width: 18px;
    color: #8d86eb;
    transform: rotate(45deg);
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
`

export const ChangeNodeNodesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  max-height: 315px;
  overflow-y: scroll;
`

export const ChangeNodeNodesListItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #8d86eb;
  margin-top: 12px;
  border-radius: 10px;
  cursor: pointer;
  border: ${({ isSelected }) => (isSelected ? '1px solid #86d4c9' : ' 1px solid #503eaa;')};

  &.add_node {
    justify-content: space-between;
    cursor: default;

    .add-new-node-handler {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #8d86eb;
      cursor: pointer;
    }

    #inputStyled {
      max-width: 70px;

      input {
        width: 70px;
        border: none;
        padding: 0;
        color: #6a6a9b;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user-url {
    color: #6a6a9b;
    font-size: 16px;
  }

  .img_wrapper {
    height: 35px;
    width: 35px;
    margin-right: 18px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
`
