import styled, { keyframes } from 'styled-components/macro'
import { backgroundColor, headerColor, primaryColor, subTextColor, downColor, borderColor } from 'styles'

export const InputStyled = styled.div`
  position: relative;
  width: 100%;

  .input_label {
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    color: #77a4f2;
    top: -20px;
    left: 5px;
  }
`

export const InputComponentContainer = styled.div`
  display: block;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const InputComponent = styled.input`
  width: 100%;
  height: 40px;
  background-color: ${backgroundColor};
  font-weight: 500;
  border: 1px solid ${borderColor};
  margin: 0;
  color: ${headerColor};
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  padding: 13px 45px 13px 20px;
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  will-change: border-color, box-shadow;
  font-size: 14px;

  &::placeholder {
    color: #6a6a9b;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${primaryColor}19;
    border-color: ${primaryColor}7F;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const InputLabel = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-39%);
  color: ${headerColor};
  font-size: 18px;
  font-weight: 600;
  display: flex;

  .input_label_text {
    font-size: 18px;
    line-height: 18px;
    color: #585495;
    margin: 0 3px;
    cursor: pointer;

    &.active {
      color: #86d4c9;
    }
  }
`

const zoomIn = keyframes`
  from {
    transform:scale(.2);
    opacity:0
  }
  to {
    transform:scale(1);
    opacity:1
  }
`

export const InputStatus = styled.div`
  display: block;
  position: absolute;
  top: 14px;
  right: 16px;
  z-index: 1;
  line-height: 13px;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  will-change: transform, opacity;

  &.with-text {
    right: 66px;
  }

  &.error {
    background-image: url('/icons/input-error.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    height: 15px;
    width: 15px;
  }

  &.success {
    background-image: url('/icons/input-success.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    height: 12px;
    width: 17px;
  }
`

export const InputIcon = styled.svg`
  display: block;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  stroke: ${subTextColor};
`

const slideDown = keyframes`
  from {
    transform: translate3d(0, -10px, 0);
    opacity:0
  }
  to {
    transform: translate3d(0, 0px, 0);
    opacity:1
  }
`

export const InputErrorMessage = styled.div`
  color: ${downColor};
  line-height: 24px;
  will-change: transform, opacity;
  animation: ${slideDown} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const InputSpacer = styled.div`
  height: 10px;
`
