import styled from 'styled-components/macro'
import {} from 'styles'

export const TimerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px auto 0 auto;
  width: fit-content;
  height: 50px;

  @media (max-width: 769px) {
    height: 30px;
  }

  .time,
  .divider {
    font-weight: 700;
    font-size: 40px;
    color: #86d4c9;

    @media (max-width: 769px) {
      font-size: 30px;
    }
  }

  .timer_section_wrapper {
    min-width: 60px;
    .time {
      margin: 0 auto;
      line-height: 30px;
      text-align: center;
    }

    .time_part {
      font-weight: 400;
      font-size: 8px;
      line-height: 15px;
      text-align: center;
      color: #8d86eb;
      text-transform: uppercase;
    }

    @media (max-width: 769px) {
      width: 50px;

      .time_part {
        font-size: 7px;
      }
    }
  }

  .divider {
    margin: 0 10px;
    align-self: flex-start;

    &:last-child {
      display: none;
    }

    @media (max-width: 769px) {
      margin: 0 5px;
      margin-top: -4px;
    }
  }
`
