import styled from 'styled-components/macro'

export const PopupContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px 40px 40px;
  background: #160e3f;
  border: 1px solid #86d4c9;
  border-radius: 10px;
  height: fit-content;
  max-width: 750px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.start-verification {
    .tip {
      width: fit-content;
      margin: 30px auto 0 auto;
      font-size: 13px;
      line-height: 12px;
      color: #77a4f2;
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #86d4c9;
      }
    }

    @media (max-width: 769px) {
      padding: 30px 20px 40px 20px;
      max-width: 95vw;
    }
  }

  &.buy-MVK {
    .input_wrappers {
      display: flex;
      margin-top: 65px;
      column-gap: 15px;
    }

    .bottom_wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      &.xtz-popup {
        justify-content: flex-end;
      }

      button {
        max-width: 330px;
      }
    }

    .exchanging_info_wrapper {
      max-width: 340px;
      margin-top: 30px;

      &.xtz-popup {
        max-width: 280px;
        margin-top: 10px;
      }
    }

    @media (max-width: 769px) {
      max-width: 90vw;

      .input_wrappers {
        row-gap: 30px;
        flex-direction: column;
      }
    }

    @media (max-width: 625px) {
      padding: 30px 20px 40px 20px;
      .bottom_wrapper {
        row-gap: 10px;
        flex-direction: column;
        align-items: center;

        .svg_wrapper {
          display: flex;
        }

        button {
          max-width: 90%;
        }
      }
    }
  }

  &.be-mavryk-buy-tokens {
    .learn-more-link {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 21px;
      color: #8d86eb;
      margin-top: 10px;
      cursor: pointer;
      width: fit-content;

      svg {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }

    .buttons_wrapper {
      display: flex;
      margin-top: 50px;
    }

    @media (max-width: 769px) {
      max-width: 90vw;

      .buttons_wrapper {
        row-gap: 15px;
        flex-direction: column;
      }
    }

    @media (max-width: 500px) {
      max-width: 90vw;

      .buttons_wrapper {
        row-gap: 15px;
        flex-direction: column;
      }
    }
  }
`

export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #8d86eb;
  position: relative;

  &.change_node {
    margin: 0 auto;
    width: fit-content;
    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    width: 77px;
    height: 4px;
    background: #503eaa;
    position: absolute;
    bottom: -10px;
    left: 0;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    /* text-align: center; */

    &::before {
      height: 2px;
    }
  }
`

export const DescrText = styled.div`
  max-width: 620px;
  font-size: 16px;
  line-height: 24px;
  color: #77a4f2;
  margin-top: 30px;

  &.change_node {
    text-align: center;
    padding: 0 10px;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const ExchangeInfoItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #77a4f2;
    width: fit-content;
  }

  .info {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    color: #86d4c9;
    width: fit-content;
    display: flex;

    #switch {
      width: 12px;
      height: 8px;
      fill: #8d86eb;
      margin: 0 8px 0 12px;
    }

    #pencil {
      width: 13px;
      height: 12px;
      margin-left: 5px;
    }
  }
`

export const AcceptedBlock = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  color: #77a4f2;

  svg {
    margin-left: 15px;
  }

  #visaIcon {
    width: 73px;
    height: 25px;
  }

  #mcIcon {
    width: 36px;
    height: 25px;
  }

  &.buy_MVK {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;

    .svg_wrapper {
      margin-top: 12px;
    }
  }

  @media (max-width: 625px) {
    &.buy_MVK {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .svg_wrapper {
        margin-top: 0;
      }
    }
  }
`

export const VerificationItemsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  padding: 0 30px;

  .verification-item-block {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    color: #8d86eb;
    max-width: 260px;
    width: 100%;

    &:nth-child(odd) {
      max-width: 330px;
    }

    #creditCardIcon {
      width: 55px;
      height: 35px;
    }

    #selfieIcon {
      width: 50px;
      height: 50px;
    }

    #photoIdIcon {
      width: 47px;
      height: 28px;
    }

    #documentIcon {
      width: 36px;
      height: 45px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85px;
      height: 85px;
      background: #080628;
      border-radius: 100%;
      margin-right: 12px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 769px) {
      font-size: 14px;
      max-width: 220px;

      &:nth-child(odd) {
        max-width: 300px;
      }

      #creditCardIcon {
        width: 45px;
        height: 25px;
      }

      #selfieIcon {
        width: 40px;
        height: 40px;
      }

      #photoIdIcon {
        width: 37px;
        height: 18px;
      }

      #documentIcon {
        width: 26px;
        height: 35px;
      }

      .icon {
        width: 65px;
        height: 65px;
        margin-right: 8px;
      }
    }

    @media (max-width: 660px) {
      max-width: 100%;

      &:nth-child(odd) {
        max-width: 100%;
      }

      #creditCardIcon {
        width: 35px;
        height: 20px;
      }

      #selfieIcon {
        width: 30px;
        height: 30px;
      }

      #photoIdIcon {
        width: 37px;
        height: 18px;
      }

      #documentIcon {
        width: 20px;
        height: 30px;
      }

      .icon {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }
    }
  }
  @media (max-width: 660px) {
    margin: 20px auto 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    row-gap: 10px;
  }
`

export const WertIoPopup = styled.div<{ display?: boolean }>`
  visibility: ${({ display }) => (display ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 535px;
  width: 100%;
  background: #160e3f;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #86d4c9;
  height: 660px;
  display: flex;
  justify-content: center;

  @media (max-width: 550px) {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    padding: 30px 0px;
    border-radius: 0;
    border: none;
  }
`
