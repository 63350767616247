import { setMVKPriceAction } from 'actions'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NUMBER_FORMATTER } from 'utils/consts'
import { MVKStatsInterface, PurschasedStatsTable, State, TokensNumbersInfo } from 'utils/interfaces'
import { checkIfElementVisible } from 'utils/utils'
import { Timer } from '../Timer/Timer.controller'
import { ToggleButton } from '../ToggleButton/Toggle-button.view'
import {
  ContentWrapperStyled,
  ContentGrid,
  ContentTextSection,
  ContentDynamicDataSection,
  ContentAssetsBlock,
  ContentAssetsBlockMap,
  JoinMVKButton,
  ContentTextNumberWrapper,
  ContentPriceChangeMarker,
  StarshipTrackWrapper,
  StarshipWrapper,
  ContentTextArticleName,
} from './Content.style'

const ContentLeftPart = () => {
  return (
    <ContentTextSection>
      <div className="subtitle">The First Tezos </div>
      <div className="title">DAO Governed Financial Ecosystem</div>

      <ContentTextArticleName>
        <div className="article_name">
          <div className="article_subname">About Us</div>
          <div className="article_title">Why invest in Mavryk Finance?</div>
          <a href="https://mavryk.finance/litepaper" target="_blank" rel="noreferrer" className="litepaper_link">
            Litepaper
          </a>
        </div>
      </ContentTextArticleName>

      <div className="article_content">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
        a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </div>

      <div className="article_content">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
        a type specimen book.
      </div>

      <div className="video_wrapper">
        <svg>
          <use xlinkHref="/icons/sprites.svg#playVideo" />
        </svg>
        <div className="links_wrapper">
          <div className="main_link">Play Video</div>
          <div className="sublink">How it works</div>
        </div>
      </div>
    </ContentTextSection>
  )
}

const FloatingPreviewBlock = ({ openModal, isVisible }: { openModal: () => void; isVisible: boolean }) => {
  return (
    <ContentDynamicDataSection className="floating" isVisible={isVisible}>
      <JoinMVKButton onClick={openModal}>
        <svg>
          <use xlinkHref="/icons/sprites.svg#ISOSale" />
        </svg>
        Join & Buy Tokens Now
      </JoinMVKButton>
    </ContentDynamicDataSection>
  )
}

const ContentRightPart = ({
  isWalletConnected,
  openModal,
  timerCount,
  selectedMVKPrice,
  MVKStats,
  MVKPrices,
  tokensNumbersInfo,
  tokensStatsTable,
}: {
  isWalletConnected: boolean
  openModal: () => void
  timerCount: number
  selectedMVKPrice: number
  MVKStats: MVKStatsInterface
  MVKPrices: Array<number>
  tokensNumbersInfo: TokensNumbersInfo
  tokensStatsTable: Array<PurschasedStatsTable>
}) => {
  const dispatch = useDispatch()
  const selectPriceHandler = (newPrice: number) => dispatch(setMVKPriceAction(newPrice))

  return (
    <ContentDynamicDataSection>
      <div className="timer_name">Ends in:</div>
      <Timer dueTimeTimestamp={timerCount} />

      {isWalletConnected ? (
        <ContentTextNumberWrapper className="price_wrapper">
          <div className="text">Live Price</div>

          <div className="price_right">
            <div className="number">$ 0.57</div>
            <ContentPriceChangeMarker className="up">+2.47%</ContentPriceChangeMarker>
          </div>
        </ContentTextNumberWrapper>
      ) : (
        <>
          <ToggleButton
            btnsData={MVKPrices}
            selected={selectedMVKPrice}
            handleSetSelectedToggler={selectPriceHandler}
          />
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Cost per MVK</div>
            <div className="number">{NUMBER_FORMATTER.format(selectedMVKPrice)}</div>
          </ContentTextNumberWrapper>
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Vesting Period</div>
            <div className="number">{NUMBER_FORMATTER.format(MVKStats.vestingPeriod)} months</div>
          </ContentTextNumberWrapper>
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Max per wallet</div>
            <div className="number">{NUMBER_FORMATTER.format(MVKStats.maxPerWallet)} MVK</div>
          </ContentTextNumberWrapper>
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Whitelist Max Amount</div>
            <div className="number">{NUMBER_FORMATTER.format(MVKStats.whitelistMaxAmount)} MVK</div>
          </ContentTextNumberWrapper>
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Total Amount at price</div>
            <div className="number">{NUMBER_FORMATTER.format(MVKStats.totalAmountAtPrice)} MVK</div>
          </ContentTextNumberWrapper>
          <ContentTextNumberWrapper className="tokens_stat">
            <div className="text">Amount available</div>
            <div className="number">{NUMBER_FORMATTER.format(MVKStats.amountAvaliable)} MVK</div>
          </ContentTextNumberWrapper>
        </>
      )}

      <JoinMVKButton onClick={openModal} id="dynamic_part_start">
        <svg>
          <use xlinkHref="/icons/sprites.svg#ISOSale" />
        </svg>
        Join & Buy Tokens Now
      </JoinMVKButton>

      <ContentTextNumberWrapper className="tokens-info-wrapper">
        <div className="token_info">
          <div className="number">$ {NUMBER_FORMATTER.format(tokensNumbersInfo.totalWorthForSale)}</div>

          <div className="text">Total worth of tokens for sale</div>
        </div>

        <div className="token_info">
          <div className="number">{NUMBER_FORMATTER.format(tokensNumbersInfo.avaliableTokens)} MVK</div>

          <div className="text">Tokens still Available</div>
        </div>
      </ContentTextNumberWrapper>

      <ContentTextNumberWrapper className="purchased">
        <div className="text">Tokens purchased</div>

        <div className="number">{NUMBER_FORMATTER.format(tokensNumbersInfo.purchasedTokens)} MVK</div>
      </ContentTextNumberWrapper>

      <StarshipTrackWrapper>
        <img src="./images/starship-track.png" alt="" />

        <StarshipWrapper leftPostition={(100 * tokensNumbersInfo.purchasedTokens) / tokensNumbersInfo.avaliableTokens}>
          <svg>
            <use xlinkHref="/icons/sprites.svg#starship" />
          </svg>
        </StarshipWrapper>
      </StarshipTrackWrapper>

      <ContentTextNumberWrapper className="total_raised">
        <div className="text">Total Raised</div>

        <div className="number">$ {NUMBER_FORMATTER.format(tokensNumbersInfo.totalRaised)}</div>
      </ContentTextNumberWrapper>

      <ContentAssetsBlock>
        <h5>Asset</h5>
        <h5>Amount</h5>
        <h5 className="right-text title">USD Value</h5>
      </ContentAssetsBlock>
      <ContentAssetsBlockMap className="scroll-block">
        {tokensStatsTable.map((rowData) => {
          return (
            <ContentAssetsBlock className="assets-block-map" key={rowData.symbol}>
              <p className="asset-name">{rowData.symbol}</p>
              <p className="asset-value">{NUMBER_FORMATTER.format(rowData.amount)}</p>
              <p className="asset-value right-text">${NUMBER_FORMATTER.format(rowData.amount * 0.25)}</p>
            </ContentAssetsBlock>
          )
        })}
      </ContentAssetsBlockMap>
    </ContentDynamicDataSection>
  )
}

export const ContentView = ({ openModal }: { openModal: () => void }) => {
  const {
    wallet,
    timerCount,
    tokensNumbersInfo,
    tokensStatsTable,
    MVKPrices,
    MVKStats,
    selectedMVKPrice,
  } = useSelector((state: State) => state)
  const [showFloatedComponent, setShowFloatedComponent] = React.useState(true)

  const onScrollChecker = () =>
    checkIfElementVisible(
      document.querySelector('#dynamic_part_start'),
      'bottom',
      'bottom',
      () => setShowFloatedComponent(true),
      () => setShowFloatedComponent(false),
    )

  React.useEffect(() => {
    let screenSize = window.innerWidth
    window.addEventListener('resize', () => {
      screenSize = window.innerWidth
    })

    if (screenSize <= 769) {
      window.addEventListener('scroll', onScrollChecker)
      onScrollChecker()
    }
    return () => {
      window.removeEventListener('scroll', onScrollChecker)
      window.removeEventListener('resize', () => {
        screenSize = window.innerWidth
      })
    }
  }, [])

  return (
    <ContentWrapperStyled id="shadow_header_target">
      <div className="content_title">Mavryk Finance Token Sale</div>
      <ContentGrid>
        <ContentLeftPart />
        <ContentRightPart
          isWalletConnected={Boolean(wallet?.ready)}
          openModal={openModal}
          timerCount={timerCount}
          selectedMVKPrice={selectedMVKPrice}
          tokensNumbersInfo={tokensNumbersInfo}
          tokensStatsTable={tokensStatsTable}
          MVKPrices={MVKPrices}
          MVKStats={MVKStats}
        />

        <FloatingPreviewBlock openModal={openModal} isVisible={showFloatedComponent} />
      </ContentGrid>
    </ContentWrapperStyled>
  )
}
