import { PopupGeneralWrapper } from '../general/Popup-general.view'
import { PopupChangeNodeView } from './Popup-change-node.view'

export const PopupChangeNode = ({ isModalOpened, closeModal }: { isModalOpened: boolean; closeModal: () => void }) => {
  return (
    <PopupGeneralWrapper
      isModalOpened={isModalOpened}
      closeModal={(e: any) => {
        if (e.target === e.currentTarget) {
          closeModal()
        }
      }}
    >
      <PopupChangeNodeView closeModal={closeModal} />
    </PopupGeneralWrapper>
  )
}
