import styled from 'styled-components/macro'

export const HomeStyled = styled.div<{ backgroundImg: string }>`
  background-image: ${({ backgroundImg }) => `url('/images/${backgroundImg}')`};
  background-repeat: no-repeat;
  margin-bottom: 256px;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: max(calc(100vh - 270px), 1380px);
  height: fit-content;

  .backdrop {
    width: 100vw;
    height: 1000vh;
    position: absolute;
    z-index: 2;
    background: #080628;
    opacity: 0.8;
    top: 0;
    left: 0;
  }

  @media (max-width: 769px) {
    background-position: bottom;
  }

  @media (max-width: 1024px) {
    .backdrop {
      display: none;
    }
  }
`
