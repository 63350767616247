import { connectWalletAction } from 'actions'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'utils/interfaces'

// import { State } from '../../../reducers'
import { ConnectWalletView } from './ConnectWallet.view'
import { TempleDAppNetwork } from '@temple-wallet/dapp'

type ConnectWalletProps = {
  type?: string | null
  className?: string
}

export const ConnectWallet = ({ type, className }: ConnectWalletProps) => {
  const dispatch = useDispatch()
  const { wallet } = useSelector((state: State) => state)
  const { myMvkTokenBalance } = useSelector((state: State) => state.user)

  const handleConnect = async () => {
    try {
      if (!wallet) {
        throw new Error('Temple Wallet not available')
      } else {
        await wallet?.connect((process.env.REACT_APP_NETWORK || 'ithacanet') as TempleDAppNetwork, {
          forcePermission: false,
        })
        const tzs = wallet?.toTezos && wallet?.toTezos()
        const accountPkh = await tzs?.wallet.pkh()
        // if (accountPkh) dispatch(getUserData(accountPkh))
        dispatch(connectWalletAction(tzs, accountPkh))
      }
    } catch (err: any) {
      console.error(`Failed to connect TempleWallet: ${err.message}`)
    }
  }

  const handleNewConnect = async () => {
    try {
      if (!wallet) {
        throw new Error('Temple Wallet not available')
      } else {
        await wallet?.connect((process.env.REACT_APP_NETWORK || 'ithacanet') as TempleDAppNetwork, {
          forcePermission: true,
        })
        const tzs = wallet?.toTezos && wallet?.toTezos()
        const accountPkh = await tzs?.wallet.pkh()
        // if (accountPkh) dispatch(getUserData(accountPkh))
        dispatch(connectWalletAction(tzs, accountPkh))
      }
    } catch (err: any) {
      console.error(`Failed to connect TempleWallet: ${err.message}`)
    }
  }
  return (
    <ConnectWalletView
      type={type}
      loading={false}
      wallet={wallet}
      ready={wallet?.ready}
      accountPkh={wallet?.accountPkh}
      myMvkTokenBalance={myMvkTokenBalance}
      handleConnect={handleConnect}
      handleNewConnect={handleNewConnect}
      className={className}
    />
  )
}
