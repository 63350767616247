import { Home } from 'pages/Home/Home.controller'
import { useState, useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { TempleWallet } from '@temple-wallet/dapp'
import { useDispatch } from 'react-redux'
import { setWalletAction } from 'actions'

export const App = () => {
  const [_, setCurrentPage] = useState('/')
  const dispatch = useDispatch()
  useEffect(() => {
    return TempleWallet.onAvailabilityChange((available) => {
      if (available) dispatch(setWalletAction(new TempleWallet(process.env.REACT_APP_NAME || 'MAVRYK')))
    })
  }, [dispatch])

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            setCurrentPage('/')
            return <Home />
          }}
        />
        {/* <Route
            exact
            path="/litepaper"
            component={() => {
              setCurrentPage('/litepaper')
              return <Litepaper />
            }}
          /> */}
      </Switch>
    </Router>
  )
}
