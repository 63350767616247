import { CSSTransition } from 'react-transition-group'
import { PopupContainer, PopupStyled } from './Popup.style'

import { useEffect } from 'react'

export const PopupGeneralWrapper = ({
  isModalOpened,
  closeModal,
  children,
}: {
  isModalOpened: boolean
  closeModal: (event: any) => void
  children?: JSX.Element
}) => {
  useEffect(() => {
    if (isModalOpened) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isModalOpened])

  return (
    <PopupStyled>
      <CSSTransition in={isModalOpened} timeout={200} classNames="popup" unmountOnExit>
        <PopupContainer onClick={closeModal}>{children}</PopupContainer>
      </CSSTransition>
    </PopupStyled>
  )
}
