import { TezosToolkit } from '@taquito/taquito'
import { TempleWallet } from '@temple-wallet/dapp'
import { UserData, MVKStatsInterface, TokensNumbersInfo, PurschasedStatsTable } from 'utils/interfaces'

export const SET_WALLET = 'SET_WALLET'
export const SELECT_RPC_NODE = 'SELECT_RPC_NODE'
export const RPC_NODES = 'RPC_NODES'
export const CONNECT = 'CONNECT'
export const GET_USER_DATA = 'GET_USER_DATA'
export const SET_TIMER_COUNT = 'SET_TIMER_COUNT'
export const SET_MVK_STATS = 'SET_MVK_STATS'
export const SET_MVK_PRICES = 'SET_MVK_PRICES'
export const SET_MVK_PRICE = 'SET_MVK_PRICE'
export const SET_TOKENS_NUBMERS_INFO = 'SET_TOKENS_NUBMERS_INFO'
export const SET_TOTAL_RAISED_TABLE = 'SET_TOTAL_RAISED_TABLE'

export const getUserDataAction = (userData: UserData) => ({
  type: GET_USER_DATA,
  userData,
})

export const selectPRCNode = (selectedRPCNode: string) => ({
  type: SELECT_RPC_NODE,
  selectedRPCNode,
})

export const setRPCNodes = (RPCNodes: Array<{ title: string; url: string }>) => ({
  type: RPC_NODES,
  RPCNodes,
})

export const setWalletAction = (wallet: TempleWallet) => ({
  type: SET_WALLET,
  wallet,
})

export const setTimerCountAction = (timerCountTimestamp: number) => ({
  type: SET_TIMER_COUNT,
  timerCountTimestamp,
})

export const setMVKPriceAction = (MVKPrice: number) => ({
  type: SET_MVK_PRICE,
  MVKPrice,
})

export const setMVKStatsAction = (MVKStats: MVKStatsInterface) => ({
  type: SET_MVK_STATS,
  MVKStats,
})

export const setMVKPricesAction = (pricesArr: Array<number>) => ({
  type: SET_MVK_PRICES,
  MVKPrices: pricesArr,
})

export const setTokensNumberInfoAction = (tokensInfo: TokensNumbersInfo) => ({
  type: SET_TOKENS_NUBMERS_INFO,
  purchasingStats: tokensInfo,
})

export const setTotalRaisedTableAction = (tableData: Array<PurschasedStatsTable>) => ({
  type: SET_TOTAL_RAISED_TABLE,
  totalRaisedTableData: tableData,
})

export const connectWalletAction = (tzs: TezosToolkit | undefined, accountPkh?: string) => ({
  type: CONNECT,
  tezos: tzs,
  ready: Boolean(tzs),
  accountPkh: accountPkh,
})

export const USER_INFO_QUERY = `
query GetUserInfo ($_eq: String = "") {
  mavryk_user(where: {address: {_eq: $_eq}}) {
    address
    mvk_balance
    participation_fees_per_share
    smvk_balance
    delegation_records {
      satellite_record {
        user_id
      }
    }
  }
}
`

export const USER_INFO_QUERY_NAME = 'GetUserInfo'

export function USER_INFO_QUERY_VARIABLES(address: string): Record<string, any> {
  return { _eq: address }
}
