import * as React from 'react'

import { LoadingIcon } from './CommaNumber.style'

export const CommaNumber = ({
  value,
  loading,
  endingText,
  className = '',
  showDecimal = true,
}: {
  value: number
  loading?: boolean
  endingText?: string
  className?: string
  showDecimal?: boolean
}) => {
  const numberWithCommas = value.toLocaleString('en-US', { maximumFractionDigits: showDecimal ? 2 : 0 })
  console.log(`${numberWithCommas} ${endingText ? ' ' + endingText : ''}`)
  console.log('numberWithCommas', numberWithCommas)

  return (
    <>
      {loading ? (
        <div className={className}>
          <LoadingIcon className={'secondary'}>
            <use xlinkHref="/icons/sprites.svg#loading" />
          </LoadingIcon>
        </div>
      ) : (
        <>
          {endingText ? (
            <div className={className}>
              <p>{`${numberWithCommas} ${endingText ? ' ' + endingText : ''}`}</p>
            </div>
          ) : (
            <div className={className}>{numberWithCommas}</div>
          )}
        </>
      )}
    </>
  )
}
