import axios from 'axios'
import { useEffect, useState } from 'react'
import {
  BE_MAVRYK_BUY_TOKENS_POPUP,
  BUY_MVK_VIA_CURRENCY_POPUP,
  BUY_MVK_VIA_XTZ_POPUP,
  MVK_USD_PRICE,
  START_VERIFICATION_POPUP,
  WERT_IO_POPUP_WRAPPER,
} from 'utils/consts'
import { calculateAssetAmountOrPrice } from 'utils/utils'
import { BuyMVKViaXTZPopup } from './payment-screens/BuyViaWallet'
import { BuyMVKViaCurrencyPopup } from './payment-screens/BuyViaWert'
import { BeMavrykBuyTokensPopup } from './payment-screens/StartModal'
import { StartVerificationPopup } from './payment-screens/StartVerification'
import { WertIoPopup } from './Popup-payment.style'

export const PopupPaymentView = ({
  activePopupId,
  changeActivePopup,
  closeModal,
}: {
  activePopupId: string
  changeActivePopup: (popupId: string) => void
  closeModal: () => void
}) => {
  const [buyForCurrencyData, setBuyForCurrencyData] = useState<{
    currencyAmount: number | string
    MVKAmount: number | string
  }>({
    currencyAmount: 0,
    MVKAmount: 0,
  })
  const [buyForXTZData, setBuyForXTZData] = useState<{
    XTZAmount: number | string
    MVKAmount: number | string
  }>({
    XTZAmount: 0,
    MVKAmount: 0,
  })

  const [prices, setPrices] = useState({
    MVK_USD: MVK_USD_PRICE,
    MVK_EUR: 0,
    XTZ: 0,
  })

  useEffect(() => {
    const getPrices = async () => {
      const { data }: any = await axios.get(
        'https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,XTZ,EUR&tsyms=USD,EUR',
      )
      setPrices({
        ...prices,
        XTZ: data.XTZ.USD,
        MVK_EUR: data.EUR.USD * MVK_USD_PRICE,
      })
    }
    getPrices()
  }, [])

  const onInputChangeHandler = ({
    currencyAmount,
    currencyType,
    assetAmount,
  }: {
    currencyAmount?: number
    currencyType: 'EUR' | 'USD' | 'XTZ'
    assetAmount?: number
  }) => {
    const res = calculateAssetAmountOrPrice({
      currencyAmount,
      currencyType,
      assetAmount,
      actualPrices: prices,
    })

    if (currencyType === 'EUR' || currencyType === 'USD') {
      setBuyForCurrencyData({
        ...buyForCurrencyData,
        ...(currencyAmount !== undefined ? { MVKAmount: res || '', currencyAmount } : {}),
        ...(assetAmount !== undefined ? { currencyAmount: res || '', MVKAmount: assetAmount } : {}),
      })
    }

    if (currencyType === 'XTZ') {
      setBuyForXTZData({
        ...buyForXTZData,
        ...(currencyAmount !== undefined ? { MVKAmount: res || '', XTZAmount: currencyAmount } : {}),
        ...(assetAmount !== undefined ? { XTZAmount: res || '', MVKAmount: assetAmount } : {}),
      })
    }
  }

  return (
    <>
      {activePopupId === BE_MAVRYK_BUY_TOKENS_POPUP ? (
        <BeMavrykBuyTokensPopup changeActivePopup={changeActivePopup} />
      ) : null}
      {activePopupId === BUY_MVK_VIA_CURRENCY_POPUP ? (
        <BuyMVKViaCurrencyPopup
          changeActivePopup={changeActivePopup}
          inputData={buyForCurrencyData}
          onInputChangeHandler={onInputChangeHandler}
        />
      ) : null}
      {activePopupId === BUY_MVK_VIA_XTZ_POPUP ? (
        <BuyMVKViaXTZPopup inputData={buyForXTZData} onInputChangeHandler={onInputChangeHandler} />
      ) : null}
      {activePopupId === START_VERIFICATION_POPUP ? (
        <StartVerificationPopup changeActivePopup={changeActivePopup} />
      ) : null}

      <WertIoPopup id="wert-io-popup-wrapper" display={activePopupId === WERT_IO_POPUP_WRAPPER} />
      <div onClick={closeModal} className="close_modal">
        +
      </div>
    </>
  )
}
