import styled from 'styled-components/macro'
import { primaryColor, headerColor, placeholderColor } from 'styles'

export const TzAddressContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const TzAddressStyled = styled.div`
  &.primary {
    color: ${primaryColor};
  }

  &.secondary {
    color: ${headerColor};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  &.transparent {
    color: ${placeholderColor};
  }

  &.bold {
    font-weight: 600;
  }
`

export const TzAddressIcon = styled.svg`
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: sub;
  margin-left: 8px;
  stroke: #86d4c9;

  &.primary {
    stroke: ${placeholderColor};
  }

  &.secondary {
    stroke: ${headerColor};
  }

  &.transparent {
    stroke: ${primaryColor};
  }
`
