// Just colors
export const whiteColor = '#FFFFFF'
export const skyColor = '#77A4F2'
export const cyanColor = '#86D4C9'
export const royalPurpleColor = '#503EAA'

// Btn colors
export const primaryColor = '#7068AA'
export const borderColor = '#4F457C'
export const secondaryColor = '#73d7c3'
export const connectWalletSecondary = '#EEEAF4'

// Text colors
export const subTextColor = '#7E8794'
export const textColor = '#29264C'
export const placeholderColor = '#F7F9FD'

// Background colors
export const backgroundColor = '#160E3F'
export const headerColor = '#8D86EB'
export const backdropColor = '#08062880'

// Indicator colors
export const upColor = '#27AE60'
export const downColor = '#FF4343'
export const warningColor = '#FF7A00'
