import React, { useState } from 'react'
import {
  BE_MAVRYK_BUY_TOKENS_POPUP,
  BUY_MVK_VIA_CURRENCY_POPUP,
  BUY_MVK_VIA_XTZ_POPUP,
  START_VERIFICATION_POPUP,
} from 'utils/consts'
import { PopupGeneralWrapper } from '../general/Popup-general.view'
import { PopupPaymentView } from './Popup-payment.view'

export const PopupPayment = ({ isModalOpened, closeModal }: { isModalOpened: boolean; closeModal: () => void }) => {
  const [activePopup, setActivePopup] = useState(BE_MAVRYK_BUY_TOKENS_POPUP)

  return (
    <PopupGeneralWrapper
      isModalOpened={isModalOpened}
      closeModal={(e: any) => {
        if (e.target === e.currentTarget) {
          closeModal()
          setTimeout(() => setActivePopup(BE_MAVRYK_BUY_TOKENS_POPUP), 400)
        }
      }}
    >
      <PopupPaymentView
        activePopupId={activePopup}
        changeActivePopup={(activePopupId: string) => {
          setActivePopup(activePopupId)
        }}
        closeModal={() => {
          closeModal()
          setTimeout(() => setActivePopup(BE_MAVRYK_BUY_TOKENS_POPUP), 400)
        }}
      />
    </PopupGeneralWrapper>
  )
}
