import { headerColor, skyColor, cyanColor } from 'styles'
import styled from 'styled-components/macro'

export const ContentWrapperStyled = styled.div`
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  background: #160e3f;
  border: 1px solid #503eaa;
  border-radius: 10px;
  min-height: 945px;
  padding: 40px 58px 110px 69px;
  position: relative;

  &::before {
    content: '';
    width: 77px;
    height: 4px;
    background: #503eaa;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .content_title {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #c0dbff;
    width: fit-content;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 769px) {
      font-size: 30px;
    }
  }

  @media (max-width: 1440px) {
    max-width: 950px;
    padding: 30px 30px 90px 35px;
  }

  @media (max-width: 1024px) {
    max-width: 85%;
    margin-top: 110px;
    margin-bottom: 50px;
  }

  @media (max-width: 769px) {
    max-width: calc(100% - 30px);
    padding: 30px 10px 90px 10px;
    margin-top: 75px;
    margin-bottom: 340px;
  }
`

export const ContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  max-height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 769px) {
    margin-top: 20px;
  }
`

export const ContentTextSection = styled.div`
  width: 100%;
  max-width: 525px;
  padding-top: 15px;

  @media (max-width: 1440px) {
    max-width: 47%;
  }

  @media (max-width: 1024px) {
    max-width: 90%;
    margin: 0 auto;
  }

  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #8d86eb;
    opacity: 0.7;

    @media (max-width: 769px) {
      font-size: 14px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #8d86eb;
    margin-top: 10px;
    position: relative;

    @media (max-width: 769px) {
      font-size: 22px;
      margin-top: 5px;
    }

    &::before {
      content: '';
      width: 77px;
      height: 4px;
      background: #503eaa;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }

  .article_content {
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #77a4f2;

    @media (max-width: 769px) {
      font-size: 15px;
      margin-top: 10px;
    }
  }

  .video_wrapper {
    display: flex;
    margin-top: 60px;
    align-items: center;

    svg {
      width: 73px;
      height: 73px;
      cursor: pointer;
    }

    .links_wrapper {
      margin-left: 15px;

      .main_link {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #8d86eb;
        cursor: pointer;
      }

      .sublink {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        margin-top: 5px;
        color: #77a4f2;
        cursor: pointer;
      }
    }

    @media (max-width: 769px) {
      margin-top: 30px;
    }
  }
`

export const ContentDynamicDataSection = styled.div<{ isVisible?: boolean }>`
  width: 100%;
  max-width: 430px;

  &.floating {
    display: none;
    position: fixed;
    bottom: ${({ isVisible }) => (isVisible ? '0' : '-100vh')};
    background: #160e3f;
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    border-color: #86d4c9;
    border-radius: 10px 10px 0px 0px;
    max-width: 92%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    padding-bottom: 20px;
    z-index: 20;
    transition: 1s all;

    @media (max-width: 769px) {
      display: block;
      max-width: calc(100% - 30px);
    }
  }

  @media (max-width: 1440px) {
    max-width: 45%;
  }

  @media (max-width: 1024px) {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 36px;
  }

  .timer_name {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #77a4f2;
    margin: 0 auto;
    width: fit-content;

    @media (max-width: 769px) {
      font-size: 14px;
    }
  }

  .timer_wrapper {
    margin: 14px auto 0 auto;
    width: fit-content;
    height: 50px;
  }
`

export const JoinMVKButton = styled.button`
  background: #86d4c9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #160e3f;
  margin: 30px auto 0 auto;
  cursor: pointer;
  border: none;

  svg {
    margin-right: 16px;
    width: 41px;
    height: 40px;
    transform: scale(0.7);
  }

  &.dark {
    background: #160e3f;
    color: #86d4c9;
    border: 1px solid #86d4c9;
  }

  &.popup_btn {
    margin: 0 3px;
    svg {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }

    &.default_svg {
      svg {
        margin-right: 10px;
        width: 41px;
        height: 40px;
      }
    }
  }

  &.start_verification {
    margin: 20px auto 0 auto;
    max-width: 330px;
  }
`

export const ContentAssetsBlock = styled.div`
  display: grid;
  grid-template-columns: 70px 150px auto;

  gap: 30px;

  &.assets-block-map {
    margin-top: 7px;
    margin-bottom: 11px;
  }

  h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${headerColor};
    margin: 0;
    margin-top: 37px;
  }

  .right-text {
    text-align: right;

    &.title {
      padding-right: 15px;
    }
  }

  .asset-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${skyColor};
    margin: 0;
    margin-top: 4px;
  }

  .asset-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${cyanColor};
    margin: 0;
    margin-top: 4px;
  }

  @media (max-width: 1440px) {
    grid-template-columns: 70px 100px auto;
  }

  @media (max-width: 540px) {
    grid-template-columns: 15% 25% auto;

    .asset-name {
      font-size: 14px;
      line-height: 14px;
    }

    .asset-value {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @media (max-width: 400px) {
    .asset-name {
      font-size: 12px;
      line-height: 12px;
    }

    .asset-value {
      font-size: 12px;
      line-height: 12px;
    }
  }
`

export const ContentAssetsBlockMap = styled.div`
  max-height: 100px;
  overflow-y: scroll;
`

export const ContentTextNumberWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto 0 auto;

  @media (max-width: 520px) {
    padding: 0 3px;
  }

  &.tokens-info-wrapper {
    .token_info {
      display: flex;
      flex-direction: column;

      &:last-child {
        align-items: flex-end;
        text-align: right;
      }

      .text {
        font-size: 12px;
        margin-top: 10px;
        width: fit-content;

        @media (max-width: 520px) {
          font-size: 10px;
          line-height: 10px;
          font-weight: 400;
          max-width: 65%;
          margin-top: 5px;
        }
      }

      .number {
        font-size: 18px;

        @media (max-width: 520px) {
          font-size: 14px;
        }
      }
    }
  }

  &.purchased {
    .text {
      font-size: 12px;

      @media (max-width: 520px) {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .number {
      font-size: 18px;

      @media (max-width: 520px) {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &.tokens_stat {
    margin-top: 10px;

    .text,
    .number {
      font-size: 16px;

      @media (max-width: 520px) {
        font-size: 14px;
      }
    }

    @media (max-width: 520px) {
      .number {
        font-weight: 600;
      }
    }
  }

  &.raised {
    .text,
    .number {
      font-size: 22px;
    }

    @media (max-width: 520px) {
      .text {
        font-size: 18px;
        font-weight: 600;
      }

      .number {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &.price_wrapper {
    max-width: 325px;

    .price_right {
      display: flex;
    }
  }

  &.total_raised {
    .text {
      @media (max-width: 520px) {
        font-size: 17px;
        font-weight: 500;
      }
    }

    .number {
      @media (max-width: 520px) {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }

  .text {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #8d86eb;
  }

  .number {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #86d4c9;
  }
`

export const ContentPriceChangeMarker = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #4bcf83;
  border-radius: 5px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  &.up {
    background: rgba(39, 174, 96, 0.4);
  }

  &.down {
    background: #ff8486;
  }
`

export const ContentTextArticleName = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: flex-end;

  .article_name {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #8d86eb;
    position: relative;
    width: 100%;

    .article_subname {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      opacity: 0.7;
      margin-right: 63px;
      color: #8d86eb;
      margin-bottom: 8px;
    }

    .article_title {
      @media (max-width: 769px) {
        font-size: 16px;
        width: 100%;
        line-height: 20px;
      }
    }
  }

  .litepaper_link {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    opacity: 0.7;
    margin-right: 20px;
    color: #8d86eb;
    position: absolute;
    right: 0;
    top: 5px;

    &::before {
      position: absolute;
      top: 0;
      right: -20px;
      width: 16px;
      height: 13px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.916504 6.3335C0.916504 6.05735 1.14036 5.8335 1.4165 5.8335L15.4165 5.8335C15.6926 5.8335 15.9165 6.05735 15.9165 6.3335C15.9165 6.60964 15.6926 6.8335 15.4165 6.8335L1.4165 6.8335C1.14036 6.8335 0.916504 6.60964 0.916504 6.3335Z' fill='%238D86EB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.81295 11.937C9.61769 11.7418 9.61769 11.4252 9.81295 11.2299L14.7094 6.3335L9.81295 1.43705C9.61769 1.24179 9.61769 0.925204 9.81295 0.729942C10.0082 0.53468 10.3248 0.53468 10.5201 0.729942L15.7701 5.97994C15.8638 6.07371 15.9165 6.20089 15.9165 6.3335C15.9165 6.4661 15.8638 6.59328 15.7701 6.68705L10.5201 11.937C10.3248 12.1323 10.0082 12.1323 9.81295 11.937Z' fill='%238D86EB'/%3E%3C/svg%3E%0A");
      content: '';
    }
  }
`

export const StarshipTrackWrapper = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  max-width: 430px;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const StarshipWrapper = styled.div<{ leftPostition: number }>`
  position: absolute;
  top: 0;
  width: 40px;
  height: 26px;
  left: ${({ leftPostition }) => (leftPostition > 90 ? `calc(${leftPostition}% - 40px)` : `${leftPostition}%`)};

  @media (max-width: 460px) {
    top: -5px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`
