import * as React from 'react'

import { ToggleButtonWrapper, ToggleButtonItem } from './Toggle-button.style'

type ToggleButtonViewProps = {
  btnsData: number[]
  selected: number | null
  handleSetSelectedToggler: (arg0: number) => void
}

export const ToggleButton = ({ btnsData, selected, handleSetSelectedToggler }: ToggleButtonViewProps) => {
  return (
    <ToggleButtonWrapper>
      {btnsData.map((btnData) => (
        <ToggleButtonItem
          key={btnData}
          className={`${selected === btnData ? 'selected' : ''} toggle-btn`}
          onClick={() => handleSetSelectedToggler(btnData)}
        >
          ${btnData}
        </ToggleButtonItem>
      ))}
    </ToggleButtonWrapper>
  )
}
