import { defaultState } from './utils/consts'
import { State } from 'utils/interfaces'

import * as actions from './actions'

const stateReducer = (state: State = defaultState, action: any) => {
  switch (action.type) {
    case actions.SET_WALLET:
      return { ...state, wallet: action.wallet }
    case actions.SELECT_RPC_NODE:
      return { ...state, REACT_APP_RPC_PROVIDER: action.selectedRPCNode }
    case actions.RPC_NODES:
      return { ...state, RPCNodes: action.RPCNodes }
    case actions.CONNECT:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          tezos: action.tezos,
          ready: action.ready,
          accountPkh: action.accountPkh,
        },
      }
    case actions.GET_USER_DATA:
      return {
        ...state,
        user: action.userData,
      }
    case actions.SET_MVK_PRICE:
      return {
        ...state,
        selectedMVKPrice: action.MVKPrice,
      }
    case actions.SET_TIMER_COUNT:
      return {
        ...state,
        timerCount: action.timerCountTimestamp,
      }
    case actions.SET_MVK_STATS:
      return {
        ...state,
        MVKStats: { ...action.MVKStats },
      }
    case actions.SET_MVK_PRICES:
      return {
        ...state,
        MVKPrices: { ...action.MVKPrices },
      }
    case actions.SET_TOKENS_NUBMERS_INFO:
      return {
        ...state,
        tokensNumbersInfo: { ...action.purchasingStats },
      }

    case actions.SET_TOTAL_RAISED_TABLE:
      return {
        ...state,
        tokensStatsTable: [...action.totalRaisedTableData],
      }
    default:
      return state
  }
}

export default stateReducer
