import styled from 'styled-components/macro'
import { royalPurpleColor, backgroundColor, headerColor, cyanColor } from 'styles'

export const HeaderStyled = styled.div<{ withShadow: boolean }>`
  width: 100%;
  max-width: 1270px;
  max-height: 80px;
  position: fixed;
  top: 20px;
  z-index: 20;
  background-color: ${backgroundColor};
  border: 1px solid ${royalPurpleColor};
  align-items: center;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 14px 0;
  z-index: 5;

  .backShadow {
    position: absolute;
    top: -20px;
    width: 1000vw;
    left: -50vw;
    height: 286px;
    background: linear-gradient(180deg, #080628 6.29%, rgba(8, 6, 40, 0) 69.23%);
    opacity: ${({ withShadow }) => (withShadow ? 1 : 0)};
    transition: 1s all;

    @media (max-width: 1024px) {
      height: 130px;
    }
  }

  .change_node_switcher {
    position: absolute;
    right: -60px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1440px) {
    max-width: 950px;
    max-height: 70px;
  }

  @media (max-width: 1165px) {
    max-width: 870px;
  }

  @media (max-width: 1025px) {
    display: none;
  }
`

export const HeaderGrid = styled.div`
  margin: 0 auto;
  max-width: calc(100vw - 80px);
  width: 1480px;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: flex;
  font-weight: 500;
  transition: 0.25s;
  padding-left: 25px;

  @media (max-width: 1440px) {
    max-width: 950px;
  }

  @media (max-width: 1165px) {
    max-width: 870px;
  }

  .toglle_hidden_items_wrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    margin: 0 50px 0 60px;
    padding: 0 5px;
    cursor: pointer;
    align-self: center;

    div {
      background: #8d86eb;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      margin: 2px 0;
    }

    &.active {
      div {
        background: #86d4c9;
      }
    }

    @media (max-width: 1440px) {
      margin: 0 35px 0 40px;

      div {
        width: 4px;
        height: 4px;
      }
    }

    @media (max-width: 1165px) {
      margin: 0 25px 0 30px;
    }
  }

  .hidden_menu_items {
    position: absolute;
    right: 0;
    top: 90px;
    background: #160e3f;
    border: 1px solid #503eaa;
    box-shadow: 0px 6px 30px rgba(2, 3, 3, 0.02), 0px 8px 10px rgba(2, 3, 3, 0.04);
    border-radius: 10px;
    padding: 21px 8px 21px 8px;
    display: flex;
    flex-direction: column;
    max-width: 356px;
    width: 100%;
    z-index: 15;

    a {
      display: block;
      padding: 6px 0 6px 20px;
      text-align: left;
      border-radius: 5px;
      width: 100%;

      &:hover {
        background: #38237c;
      }
    }

    @media (max-width: 1440px) {
      max-width: 230px;
    }
  }

  a {
    color: ${headerColor};
    transition: all 0.25s linear;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-size: 25px;

    &:nth-of-type(1) {
      margin-left: 0;
    }

    @media (max-width: 1440px) {
      font-size: 19px;
      margin-right: 30px;
    }

    @media (max-width: 1165px) {
      font-size: 17px;
      margin-right: 20px;
    }
  }

  a:hover {
    color: ${cyanColor};
  }

  label {
    margin-top: 25px;
    margin-left: 30px;
  }
`

export const HeaderLogo = styled.div<{ src: string }>`
  background: url(${(props) => props.src}) no-repeat;
  height: 60px;
  z-index: 1;
  width: 276px;
  transition: margin-top 0.25s linear;

  @media (max-width: 1440px) {
    background-size: cover;
    height: 25px;
    width: 138px;
    margin-right: 10px;
  }

  @media (max-width: 1024px) {
    background-size: cover;
    height: 32px;
    width: 60px;
    margin-right: 10px;
  }
`

export const HeaderMobileStyled = styled.div<{ withShadow: boolean }>`
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  height: 55px;
  background: #160e3f;
  z-index: 100;

  .backShadow {
    position: absolute;
    top: 55px;
    width: 1000vw;
    left: -50vw;
    height: 100px;
    background: linear-gradient(180deg, #080628 6.29%, rgba(8, 6, 40, 0) 69.23%);
    opacity: ${({ withShadow }) => (withShadow ? 1 : 0)};
    transition: 1s all;
  }

  .container {
    max-width: 85%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1025px) {
    display: block;
  }

  @media (max-width: 500px) {
    padding: 0 17px;

    .container {
      max-width: 100%;
    }
  }

  .change_node_switcher {
    width: 40px;
    height: 40px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .toglle_mobile_menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    div {
      opacity: 0.8;
      background: #8d86eb;
      width: 24px;
      height: 2px;
      border-radius: 2px;
      margin: 3px 0;
    }

    #closeIcon {
      width: 32px;
      height: 32px;
    }
  }
`

export const MobileMenuContainer = styled.div<{
  isHiddenItemsVisible: boolean
  isMenuExpanded: boolean
}>`
  position: absolute;
  left: 0;
  top: ${({ isMenuExpanded }) => (isMenuExpanded ? '55px' : '-100vh')};
  height: 100vh;
  width: 100vw;
  background: #160e3f;
  transition: 0.5s all;
  z-index: 5;
  padding-top: 20px;

  .main_menu {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    padding: 0 65px;

    a {
      color: #8d86eb;
      padding: 10px 0;
      font-size: 25px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #38237c;
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  .toglle_hidden_items_wrapper {
    display: flex;
    height: fit-content;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    align-self: center;

    div {
      background: #8d86eb;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      margin: 0 2px;
    }

    &.active {
      div {
        background: #86d4c9;
      }
    }
  }

  .hidden_menu_items {
    width: 100%;
    margin-top: 15px;
    background: #080628;
    padding: 34px 65px 25px 64px;
    display: flex;
    flex-direction: column;
    transition: 0.5s all;
    opacity: ${({ isHiddenItemsVisible }) => (isHiddenItemsVisible ? '1' : '0')};

    a {
      color: #8d86eb;
      padding: 10px 0;
      font-size: 25px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #38237c;
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
`
