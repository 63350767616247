import styled, { keyframes } from 'styled-components/macro'
import { textColor, headerColor } from 'styles'

const turn = keyframes`
  100% {
      transform: rotate(360deg);
  }
`

export const LoadingIcon = styled.svg`
  width: 20px;
  height: 20px;
  vertical-align: sub;
  stroke: ${textColor};
  stroke-width: 2px;
  stroke-dashoffset: 94.248;
  stroke-dasharray: 47.124;
  animation: ${turn} 1.6s linear infinite forwards;

  &.secondary {
    stroke: ${headerColor};
  }

  &.transparent {
    stroke: ${textColor};
  }
`
