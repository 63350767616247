import { JoinMVKButton } from 'app/App.components/Content/Content.style'
import { Input } from 'app/App.components/Input/Input.controller'
import { TzAddress } from 'app/App.components/TzAddress/TzAddress.view'
import { useState } from 'react'
import { START_VERIFICATION_POPUP } from 'utils/consts'
import { inputNumberValidation } from 'utils/utils'
import { PopupContainerWrapper, PopupTitle, ExchangeInfoItem, AcceptedBlock } from '../Popup-payment.style'

export const BuyMVKViaCurrencyPopup = ({
  inputData,
  changeActivePopup,
  onInputChangeHandler,
}: {
  inputData: {
    currencyAmount: number | string
    MVKAmount: number | string
  }
  changeActivePopup: (arg0: string) => void
  // TODO: set actual type
  onInputChangeHandler: any
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState<'USD' | 'EUR'>('USD')

  return (
    <PopupContainerWrapper className="buy-MVK">
      <PopupTitle>Buy MVK</PopupTitle>

      <div className="input_wrappers">
        <Input
          type={'number'}
          placeholder={'0'}
          onChange={(e: any) => {
            if (inputNumberValidation(e.target.value)) {
              onInputChangeHandler({
                currencyAmount: e.target.value,
                currencyType: selectedCurrency,
              })
            }
          }}
          value={inputData.currencyAmount}
          pinnedText={
            <>
              <div
                onClick={() => {
                  onInputChangeHandler({
                    currencyAmount: inputData.currencyAmount,
                    currencyType: 'USD',
                  })
                  setSelectedCurrency('USD')
                }}
                className={`input_label_text ${selectedCurrency === 'USD' ? 'active' : ''}`}
              >
                USD
              </div>
              <div
                onClick={() => {
                  onInputChangeHandler({
                    currencyAmount: inputData.currencyAmount,
                    currencyType: 'EUR',
                  })
                  setSelectedCurrency('EUR')
                }}
                className={`input_label_text  ${selectedCurrency === 'EUR' ? 'active' : ''}`}
              >
                EUR
              </div>
            </>
          }
          label={'You pay'}
        />

        <Input
          type={'number'}
          placeholder={'0'}
          onChange={(e: any) => {
            if (inputNumberValidation(e.target.value)) {
              onInputChangeHandler({
                assetAmount: e.target.value,
                currencyType: selectedCurrency,
              })
            }
          }}
          value={inputData.MVKAmount}
          pinnedText={'MVK'}
          label={'You get'}
        />
      </div>

      <div className="exchanging_info_wrapper">
        <ExchangeInfoItem>
          <div className="text">Fee 5% included</div>
          <div className="info">758.07</div>
        </ExchangeInfoItem>

        <ExchangeInfoItem>
          <div className="text">Rate updates in 00:30 </div>
          <div className="info">
            $ 34.898
            <svg id="switch">
              <use xlinkHref="/icons/sprites.svg#switch" />
            </svg>
            1 BTC
          </div>
        </ExchangeInfoItem>

        <ExchangeInfoItem>
          <div className="text">Transaction Fee included</div>
          <div className="info">0.0134 XTZ</div>
        </ExchangeInfoItem>

        <ExchangeInfoItem>
          <div className="text">Your Tezos wallet address</div>
          <div className="info">
            <TzAddress tzAddress={'tz1Th8CjcjbTh8CjcjbTh8Cjcjb'} hasIcon={false} />
          </div>
        </ExchangeInfoItem>
      </div>

      <div className="bottom_wrapper">
        <JoinMVKButton onClick={() => changeActivePopup(START_VERIFICATION_POPUP)} className="popup_btn default_svg">
          <svg>
            <use xlinkHref="/icons/sprites.svg#ISOSale" />
          </svg>
          Continue
        </JoinMVKButton>
        <AcceptedBlock className="buy_MVK">
          accepted here
          <div className="svg_wrapper">
            <svg id="visaIcon">
              <use xlinkHref="/icons/sprites.svg#visaIcon" />
            </svg>
            <svg id="mcIcon">
              <use xlinkHref="/icons/sprites.svg#mcIcon" />
            </svg>
          </div>
        </AcceptedBlock>
      </div>
    </PopupContainerWrapper>
  )
}
