import WertWidget from '@wert-io/widget-initializer'
import { JoinMVKButton } from 'app/App.components/Content/Content.style'
import { VERIFICATION_GRID_ITEMS, WERT_IO_POPUP_WRAPPER } from 'utils/consts'
import { DescrText, PopupContainerWrapper, PopupTitle, VerificationItemsWrapper } from '../Popup-payment.style'

export const StartVerificationPopup = ({ changeActivePopup }: { changeActivePopup: (arg0: string) => void }) => (
  <PopupContainerWrapper className="start-verification">
    <PopupTitle>Let’s get started</PopupTitle>
    <DescrText>
      Buying MVK with a credit card requires users to go through KYC. We are using wert.io. To proceed with your
      purchase of MVK, we will need:
    </DescrText>
    <VerificationItemsWrapper>
      {VERIFICATION_GRID_ITEMS.map(({ icon, text }) => (
        <div className="verification-item-block" key={icon}>
          <div className="icon">
            <svg id={icon}>
              <use xlinkHref={`/icons/sprites.svg#${icon}`} />
            </svg>
          </div>
          {text}
        </div>
      ))}
    </VerificationItemsWrapper>
    <div className="tip">
      average verification time <span>5 min</span>
    </div>
    <JoinMVKButton
      onClick={() => {
        const options = {
          partner_id: '01G0MCBQFJE47YZ0SECRKM81CD',
          commodity: 'XTZ',
          origin: 'https://sandbox.wert.io',
          container_id: 'wert-io-popup-wrapper',
          color_background: 'transparent',
          color_buttons: '#86D4C9',
          color_buttons_text: '#160E3F',
          color_secondary_buttons: '#86D4C9',
          color_secondary_buttons_text: '#160E3F',
          color_main_text: '#8D86EB',
          color_secondary_text: '#77A4F2',
          color_icons: '#8D86EB',
          color_links: '#86D4C9',
          color_success: '#86D4C9',
          color_warning: '#F79E1B',
          color_error: '#EB001B',
          theme: 'dark',
          height: 600,
          width: 400,
          listeners: {
            loaded: () => changeActivePopup(WERT_IO_POPUP_WRAPPER),
          },
        }
        const wertWidget = new WertWidget(options)
        wertWidget.mount()
      }}
      className="popup_btn default_svg start_verification"
    >
      <svg>
        <use xlinkHref="/icons/sprites.svg#ISOSale" />
      </svg>
      Start Verification
    </JoinMVKButton>
  </PopupContainerWrapper>
)
