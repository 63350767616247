import React, { useEffect, useRef, useState } from 'react'

import { Content } from 'app/App.components/Content/Content.controller'
import { Footer } from 'app/App.components/Footer/Footer.controller'
import { Header } from 'app/App.components/Header/Header.controller'

import { HomeStyled } from './Home.style'
import { PopupPayment } from 'app/App.components/Popup/payment/Popup-payment.controller'
import { PopupChangeNode } from 'app/App.components/Popup/change-node/Popup-change-node.controller'

export const HomeView = () => {
  const [showPaymentPopup, setShowPaymentPopup] = useState(false)
  const [showChangeNodePopup, setShowChangeNodePopup] = useState(false)
  const screenSize = useRef<number>(window.innerWidth || 1440)

  useEffect(() => {
    window.addEventListener('resize', () => {
      screenSize.current = window.innerWidth
    })
    return () => {
      window.removeEventListener('resize', () => {
        screenSize.current = window.innerWidth
      })
    }
  }, [])

  return (
    <HomeStyled
      backgroundImg={screenSize && screenSize.current < 769 ? 'mobile_main_bg.png' : 'tokensales_main_bg.png'}
    >
      <Header openChangeNodeModal={() => setShowChangeNodePopup(true)} />
      <Content openPaymentModal={() => setShowPaymentPopup(true)} />
      <PopupPayment isModalOpened={showPaymentPopup} closeModal={() => setShowPaymentPopup(false)} />
      <PopupChangeNode isModalOpened={showChangeNodePopup} closeModal={() => setShowChangeNodePopup(false)} />
      <Footer />
    </HomeStyled>
  )
}
