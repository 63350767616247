import styled from 'styled-components/macro'
import { backgroundColor, placeholderColor, cyanColor } from 'styles'

export const FooterStyled = styled.footer`
  background-color: ${backgroundColor};
  color: ${placeholderColor};
  font-weight: 400;
  font-size: 18px;
  position: absolute;
  bottom: -256px;
  max-height: 256px;
  width: 100%;
  max-width: 1360px;
  left: 50%;
  transform: translateX(-50%);
`

export const FooterContainer = styled.div`
  padding: 50px 20px;

  @media (max-width: 600px) {
    padding: 40px 25px;
  }
`

export const FooterTop = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-container);
  display: flex;
  font-weight: 500;
  align-items: baseline;
  margin-bottom: 15px;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    align-items: flex-start;
    text-align: left;
  }
`
export const FooterBottom = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-container);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    width: fit-content;
    margin-top: 24px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    margin: 24px 0 0 0;
  }
`
export const FooterSocials = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    width: fit-content;
    margin-top: 45px;
    margin: 45px auto 0 auto;
  }

  a {
    width: 60px;
    height: 60px;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media (max-width: 500px) {
      width: 45px;
      height: 45px;
    }

    &:hover {
      svg {
        fill: ${cyanColor};
      }
    }
  }

  svg {
    height: 30px;
    fill: #77a4f2;
    transition: all 0.25s linear;
  }
`

export const FooterLogo = styled.img`
  margin-top: -3px;
  z-index: 1;
  width: 270px;

  @media (max-width: 500px) {
    margin-top: 0;
    width: 200px;
  }
`

export const FooterDescription = styled.div`
  margin: 10px 0;
  max-width: 665px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`
