import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { checkIfElementVisible, checkIfTargetBoundsToOtherTarget } from 'utils/utils'
import { ConnectWallet } from '../ConnectWallet/ConnectWallet.controller'

import { HeaderGrid, HeaderMobileStyled, HeaderLogo, HeaderStyled, MobileMenuContainer } from './Header.style'

export const HeaderView = ({ openModal }: { openModal: () => void }) => {
  const darkThemeEnabled = useSelector((state: any) => state.darkThemeEnabled)

  const logoUrl = darkThemeEnabled ? '/logo-dark.svg' : '/logo-light.svg'
  const topColor = darkThemeEnabled ? 'rgb(22, 14, 63)' : '#6598c9'
  const [hiddenItemsOpened, setHiddenItemsOpened] = useState<boolean>(false)
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState<boolean>(false)
  const [addShadowToheader, setAddShadowToHeader] = useState<boolean>(false)

  useEffect(() => {
    if (hiddenItemsOpened || mobileMenuExpanded) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [hiddenItemsOpened, mobileMenuExpanded])

  const onScrollChecker = () =>
    checkIfTargetBoundsToOtherTarget(
      document.querySelector('#shadow_header_target'),
      document.querySelector('#shadow_header'),
      'bottom',
      'top',
      () => setAddShadowToHeader(false),
      () => setAddShadowToHeader(true),
    )

  React.useEffect(() => {
    window.addEventListener('scroll', onScrollChecker)
    onScrollChecker()
    return () => {
      window.removeEventListener('scroll', onScrollChecker)
    }
  }, [])

  return (
    <>
      <HeaderStyled id="shadow_header" withShadow={addShadowToheader}>
        <div className="backShadow" />
        <Helmet>
          <meta name="theme-color" content={topColor} />
        </Helmet>
        <HeaderGrid>
          <Link to="/">
            <HeaderLogo src={logoUrl} />
          </Link>

          <a href="https://mavryk.finance" target="_blank" rel="noreferrer">
            About Us
          </a>

          <a href="prototype.mavryk.finance" target="_blank" rel="noreferrer">
            DAPP
          </a>

          <a href="https://blogs.mavryk.finance/" target="_blank" rel="noreferrer">
            Blog <span style={{ fontSize: '17px', marginLeft: '5px' }}>🔥</span>
          </a>

          <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
            Docs
          </a>

          <div
            className={`toglle_hidden_items_wrapper ${hiddenItemsOpened ? 'active' : ''}`}
            onClick={() => setHiddenItemsOpened(!hiddenItemsOpened)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          {hiddenItemsOpened ? (
            <>
              <div className="hidden_menu_items">
                <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
                  Github
                </a>
                <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
                  Security
                </a>
                <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
                  Mavryk Bakery
                </a>
                <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
                  DAO Bakery
                </a>
              </div>
            </>
          ) : null}

          <ConnectWallet />
        </HeaderGrid>

        <div className="change_node_switcher" onClick={openModal}>
          <svg>
            <use xlinkHref="/icons/sprites.svg#settings" />
          </svg>
        </div>
      </HeaderStyled>

      {hiddenItemsOpened ? <div className="backdrop" onClick={() => setHiddenItemsOpened(false)} /> : null}

      <HeaderMobileStyled withShadow={addShadowToheader}>
        <div className="backShadow" />
        <div className="container">
          <div className="change_node_switcher" onClick={openModal}>
            <svg>
              <use xlinkHref="/icons/sprites.svg#settings" />
            </svg>
          </div>

          <Link to="/">
            <HeaderLogo src={'mobile-logo.svg'} />
          </Link>

          <div className="toglle_mobile_menu" onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}>
            {mobileMenuExpanded ? (
              <svg id="closeIcon">
                <use xlinkHref="/icons/sprites.svg#closeIcon" />
              </svg>
            ) : (
              <>
                <div></div>
                <div></div>
                <div></div>
              </>
            )}
          </div>
        </div>

        <MobileMenuContainer isHiddenItemsVisible={hiddenItemsOpened} isMenuExpanded={mobileMenuExpanded}>
          <ConnectWallet />

          <div className="main_menu">
            <a href="https://mavryk.finance" target="_blank" rel="noreferrer">
              About Us
            </a>

            <a href="prototype.mavryk.finance" target="_blank" rel="noreferrer">
              DAPP
            </a>

            <a href="https://blogs.mavryk.finance/" target="_blank" rel="noreferrer">
              Blog <span style={{ fontSize: '20px', marginLeft: '0px' }}>🔥</span>
            </a>

            <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
              Docs
            </a>
          </div>

          <div
            className={`toglle_hidden_items_wrapper ${hiddenItemsOpened ? 'active' : ''}`}
            onClick={() => setHiddenItemsOpened(!hiddenItemsOpened)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className="hidden_menu_items">
            <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
              Github
            </a>
            <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
              Security
            </a>
            <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
              Mavryk Bakery
            </a>
            <a href="https://mavryk.finance/" target="_blank" rel="noreferrer">
              DAO Bakery
            </a>
          </div>
        </MobileMenuContainer>
      </HeaderMobileStyled>
    </>
  )
}
