import { TimerStyled } from './Timer.style'

export const TimerView = ({ timerData }: { timerData: Record<string, number> }) => {
  const convertedToArrTimerData = Object.entries(timerData)

  return (
    <TimerStyled>
      {convertedToArrTimerData.length
        ? convertedToArrTimerData.map(([key, value], idx) => {
            return (
              <>
                <div className="timer_section_wrapper" key={value} >
                  <div className="time">{value.toString().length < 2 ? value.toString().padStart(2, '0') : value}</div>
                  <div className="time_part">{key}</div>
                </div>
                <div className="divider">:</div>
              </>
            )
          })
        : "time's up!"}
    </TimerStyled>
  )
}
