import { JoinMVKButton } from 'app/App.components/Content/Content.style'
import { Input } from 'app/App.components/Input/Input.controller'
import { TzAddress } from 'app/App.components/TzAddress/TzAddress.view'
import { useSelector } from 'react-redux'
import { State, WalletState } from 'utils/interfaces'
import { inputNumberValidation, performPaymentViaWallet } from 'utils/utils'
import { PopupContainerWrapper, PopupTitle, ExchangeInfoItem } from '../Popup-payment.style'

export const BuyMVKViaXTZPopup = ({
  inputData,
  onInputChangeHandler,
}: {
  inputData: {
    XTZAmount: number | string
    MVKAmount: number | string
  }
  onInputChangeHandler: (arg0: any) => void
}) => {
  const { wallet } = useSelector((state: State) => state)
  return (
    <PopupContainerWrapper className="buy-MVK">
      <PopupTitle>Buy MVK</PopupTitle>

      <div className="input_wrappers">
        <Input
          type={'number'}
          placeholder={'0'}
          required
          onChange={(e: any) => {
            if (inputNumberValidation(e.target.value)) {
              onInputChangeHandler({
                currencyAmount: e.target.value,
                currencyType: 'XTZ',
              })
            }
          }}
          value={inputData.XTZAmount}
          pinnedText={'XTZ'}
          label={'You pay'}
        />

        <Input
          type={'number'}
          placeholder={'0'}
          onChange={(e: any) => {
            if (inputNumberValidation(e.target.value)) {
              onInputChangeHandler({
                assetAmount: e.target.value,
                currencyType: 'XTZ',
              })
            }
          }}
          value={inputData.MVKAmount}
          pinnedText={'MVK'}
          label={'You get'}
        />
      </div>

      <div className="exchanging_info_wrapper xtz-popup">
        <ExchangeInfoItem>
          <div className="text">Your Tezos wallet address</div>
          <div className="info">
            <TzAddress tzAddress={'tz1Th8CjcjbTh8CjcjbTh8Cjcjb'} hasIcon />
          </div>
        </ExchangeInfoItem>
      </div>

      <div className="bottom_wrapper xtz-popup">
        <JoinMVKButton
          onClick={() => {
            performPaymentViaWallet(inputData.XTZAmount, wallet)
          }}
          className="popup_btn default_svg"
        >
          <svg>
            <use xlinkHref="/icons/sprites.svg#ISOSale" />
          </svg>
          Continue
        </JoinMVKButton>
      </div>
    </PopupContainerWrapper>
  )
}
